<template>
  <div class="change-password-sheet">
      <intract-bottom-sheet
        title="Change Password"
        :visible="visible"
        @close="closePasswordSheet"
      >
        <v-form
        v-model="newPasswordForm.valid"
        class="mx-4 mt-1"
        @submit.prevent="changePassword"
        >
            <!-- text field -->
            <v-text-field
            required
            class="mb-2"
            persistent-hint
            outlined
            :append-icon="newPasswordForm.showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="newPasswordForm.showCurrentPassword ? 'text' : 'password'"
            @click:append="newPasswordForm.showCurrentPassword = !newPasswordForm.showCurrentPassword"
            @input="newPasswordForm.currentPasswordError = null"
            hide-details="auto"
            :error-messages="newPasswordForm.currentPasswordError"
            v-model="newPasswordForm.currentPassword"
            >
            <template #label>
                Current Password<span class="red--text"
                ><strong> * </strong></span
                >
            </template>
            </v-text-field>

            <v-text-field
            class="mb-2"
            required
            persistent-hint
            outlined
            @input="newPasswordForm.passwordError = null"
            :rules="newPasswordForm.newPasswordRules"
            hide-details="auto"
            :append-icon="newPasswordForm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="newPasswordForm.showNewPassword ? 'text' : 'password'"
            @click:append="newPasswordForm.showNewPassword = !newPasswordForm.showNewPassword"
            :error-messages="newPasswordForm.passwordError"
            v-model="newPasswordForm.newPassword"
            >
            <template #label>
                New Password<span class="red--text"
                ><strong> * </strong></span
                >
            </template>
            </v-text-field>
            <v-text-field
            required
            class="mb-3"
            persistent-hint
            outlined
            :append-icon="newPasswordForm.showRetypePassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="newPasswordForm.showRetypePassword ? 'text' : 'password'"
            @click:append="newPasswordForm.showRetypePassword = !newPasswordForm.showRetypePassword"
            @input="newPasswordForm.retypePasswordError = null"
            hide-details="auto"
            :error-messages="newPasswordForm.retypePasswordError"
            v-model="newPasswordForm.retypePassword"
            >
            <template #label>
                Retype New Password<span class="red--text"
                ><strong> * </strong></span
                >
            </template>
            </v-text-field>
            <v-btn
            type="submit"
            :disabled="!newPasswordForm.valid || isLoading"
            block
            @click="changePassword"
            color="primary"
            >Change Password</v-btn
            >
        </v-form>
      </intract-bottom-sheet>
    </div>
</template>

<script>
function initialState() {
      return {
        // visible: false,
        showSupportSheet: false,
        newPasswordForm: {
          valid: false,
          newPasswordRules: [
            (v) => !!v || "Required",
            (v) =>
              !v ||
              v.length == 0 ||
              v.length >= 8 ||
              "Must be more than 8 characters",
          ],

          currentPassword: null, 
          showCurrentPassword: false,
          newPassword: null,
          showNewPassword: false,
          retypePassword: null,
          showRetypePassword: false,
          currentPasswordError: null,
          passwordError: null,
          retypePasswordError: null,
        },
    };
}

import IntractBottomSheet from "@components/generics/IntractBottomSheet.vue"
import Mixins from "@utils/mixins";

export default {
    name: "ChangePasswordSheet",
    mixins: [Mixins.essentials],

    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    }, 

    data() {
        return initialState();
    }, 

    components: {
        IntractBottomSheet,
    }, 

    methods: {
        async changePassword() {
            if (
                this.newPasswordForm.newPassword != this.newPasswordForm.retypePassword
            ) {
                // this.loading = false;

                this.showSnackbar({ text: "Passwords do not match!", type: "error" });
                return;
            }
            // this.loading = true;

            var response = await this.api.call(
                this.essentials,
                this.endpoints.changePassword,
                this.api.Methods.POST,
                {
                  current_password: this.newPasswordForm.currentPassword, 
                  new_password: this.newPasswordForm.newPassword,
                }
            );
            // this.loading = false;
            if (response) {
                this.showSnackbar({
                title: "Password reset successfully!",
                type: "success",
                });
                this.closePasswordSheet();
            }   
        }, 

        async closePasswordSheet() {
          this.$emit("close"); 
          Object.assign(this.$data, initialState());
        }, 


    //     async closeDialog() {
    //         this.visible = false;
    //         Object.assign(this.$data, initialState());
    //     },
    }
}
</script>

<style>

</style>